import type { AudioPlayerData } from '~~/types'
import {
  useNuxtApp,
  ref,
  computed,
  defineStore,
  useAzBlobUrl,
  useFileSaver,
  useNativeFileSystemAdapter,
  getOptimizedImage,
  sanitizeFilename,
} from '#imports'

export const useAudioPlayer = defineStore('audioPlayer', () => {
  const audioPlayerData = ref<AudioPlayerData>({})
  const isAudioPlayerVisible = ref(false)
  const playingIndex = ref(1)
  const playingUrl = computed(() => {
    return audioPlayerData.value.demos
      ? audioPlayerData.value.demos[playingIndex.value - 1]
      : undefined
  })

  const playIcon = 'mdi-play-circle'
  const pauseIcon = 'mdi-pause-circle'

  const { $ipc } = useNuxtApp()

  function getDemosUrls(demos: string[]) {
    const { getAudioDemoUrl } = useAzBlobUrl()
    const r = []
    if (demos && demos.length > 0) {
      for (const demo of demos) r.push(getAudioDemoUrl(demo))
    }
    return r
  }

  function playAudioDemo(playerData: AudioPlayerData) {
    const { getImageUrl } = useAzBlobUrl()
    audioPlayerData.value.demos = playerData.demos ? getDemosUrls(playerData.demos) : undefined
    audioPlayerData.value.name = playerData.name
    audioPlayerData.value.image = playerData.image
      ? getOptimizedImage(getImageUrl(playerData.image))
      : undefined
    playingIndex.value = 1
    isAudioPlayerVisible.value = true
  }

  async function downloadFile() {
    // window.open(playingUrl.value, 'download') // legacy download
    const filename = sanitizeFilename(
      `${audioPlayerData.value.name}_demo-${playingIndex.value}.mp3`,
    )
    if (!playingUrl.value) throw new Error('playing url is undefined')
    if ($ipc) {
      useFileSaver().saveAs(playingUrl.value, filename)
    } else {
      await useNativeFileSystemAdapter().saveFileFromUrl(playingUrl.value as string, filename, [
        { accept: { 'audio/mpeg': ['.mp3'] } },
      ])
    }
  }

  return {
    // state
    isAudioPlayerVisible,
    audioPlayerData,
    playingIndex,
    playingUrl,

    // functions
    playIcon,
    pauseIcon,
    getDemosUrls,
    playAudioDemo,
    downloadFile,
  }
})
